import {
    UpdateCartItemsDocument,
    AddProductsToCartDocument,
    CreateGuestCartMutation,
    CreateGuestCartMutationVariables,
    CreateGuestCartDocument,
    GetCartContentDocument,
    GetCartContentQuery,
} from '../context/cart/graphql';

import {
    AddCartMutation,
    UpdateCartMutation,
    CartOperations,
} from '../interfaces/operations/cartOperations';

import {
    ApolloCache,
    ApolloClient,
} from '@apollo/client';

export class MagentoCartOperation implements CartOperations {
    private apolloClient: ApolloClient<object>;
    private updateApolloCache: (cache: ApolloCache<any>) => void;

    constructor(
        apolloClient: ApolloClient<object>,
        updateApolloCache: (cache: ApolloCache<any>) => void
    ) {
        this.apolloClient = apolloClient;
        this.updateApolloCache = updateApolloCache;
    }

    public async updateCartItemsMutation(
        cartMutation: UpdateCartMutation
    ): Promise<any> {
        try {
            await this.apolloClient.mutate({
                mutation: UpdateCartItemsDocument,
                variables: cartMutation,
            });
            await this.updateApolloCache(this.apolloClient.cache);
        } catch (e) {
            console.error(`Failed to update customer cart`, e);
        }
    }

    public async addProductsToCartMutation(
        cartMutation: AddCartMutation
    ): Promise<any> {
        try {
            await this.apolloClient.mutate({
                mutation: AddProductsToCartDocument,
                variables: cartMutation,
            });
            await this.updateApolloCache(this.apolloClient.cache);
        } catch (e) {
            console.error(`Failed to add customer cart`, e);
        }
    }

    public async createGuestCartMutation(): Promise<any> {
        try {
            await this.apolloClient.mutate<
                CreateGuestCartMutation,
                CreateGuestCartMutationVariables
            >({ mutation: CreateGuestCartDocument, fetchPolicy: 'no-cache' });
            await this.updateApolloCache(this.apolloClient.cache);
        } catch (e) {
            console.error(`Failed to create guest cart`, e);
        }
    }

    public async getCartQuery(cartId: string): Promise<GetCartContentQuery> {
        return await this.apolloClient
            .query({
                query: GetCartContentDocument,
                variables: {
                    cart_id: cartId,
                },
            })
            .then((result) => result.data)
            .catch(() => null);
    }
}