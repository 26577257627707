import { useContext } from 'react';
import {
    IAddAddressInput,
    IChangePasswordInput,
    ICreateCustomerInput,
    IRemoveAddressInput,
    ISubscribeNewsletterInput,
    IUpdateAddressInput,
    IUpdateCustomerInput,
    useAddAddressAction,
    useAllCountries,
    useChangePasswordAction,
    useCompletePasswordResetAction,
    useCreateCustomerAction,
    useCustomerAddressById,
    useCustomerAddresses,
    useRemoveAddressAction,
    useRequestPasswordResetAction,
    useSignInAction,
    useSignOutAction,
    useSubscribeNewsletterAction,
    useUpdateAddressAction,
    useUpdateCustomerAction,
} from '../context/customerAccount/';
import {
    IUpdateEmailInput,
    useUpdateEmailAction,
} from '../context/customerAccount/actions/useUpdateEmailAction';
import { useUserProfile } from '../context/customerAccount/queries/useUserProfile';
import { OrdersService } from '../interfaces/services/ordersService';
import {
    OtherBoutikServices,
    OtherBoutikServicesContext,
} from '../context/otherBoutikServices/otherBoutikServicesContext';

export interface CustomerAccountActions {
    createCustomer: (customer: ICreateCustomerInput) => Promise<void>;
    user: {
        profileHook: (isAuthenticated: boolean) => any;
        signIn: (email: string, password: string) => Promise<void>;
        signOut: () => Promise<void>;
    };
    informations: {
        subscribeNewsletter: (
            newsletterInput: ISubscribeNewsletterInput
        ) => Promise<void>;
        updateCustomer: (customer: IUpdateCustomerInput) => Promise<void>;
        updateCustomerEmail: (customer: IUpdateEmailInput) => Promise<void>;
    };
    addresses: {
        countryHook: () => any;
        addressesHook: () => any;
        addressByIdHook: (id: number) => any;
        addAddress: (address: IAddAddressInput) => Promise<void>;
        removeAddress: (addressId: IRemoveAddressInput) => Promise<void>;
        updateAddress: (addressId: IUpdateAddressInput) => Promise<void>;
    };
    password: {
        changePassword: (passwordInfos: IChangePasswordInput) => Promise<void>;
        completePasswordReset: (
            email: string,
            resetPasswordToken: string,
            newPassword: string
        ) => Promise<void>;
        requestPasswordReset: (email: string) => Promise<void>;
    };
    orders: OrdersService;
}

export const useCustomerAccountActions = (): CustomerAccountActions => {
    const [signIn] = useSignInAction();
    const [signOut] = useSignOutAction();
    const [addAddress] = useAddAddressAction();
    const [removeAddress] = useRemoveAddressAction();
    const [updateAddress] = useUpdateAddressAction();
    const [changePassword] = useChangePasswordAction();
    const [completePasswordReset] = useCompletePasswordResetAction();
    const [subscribeNewsletter] = useSubscribeNewsletterAction();
    const [requestPasswordReset] = useRequestPasswordResetAction();
    const [createCustomer] = useCreateCustomerAction();
    const [updateCustomer] = useUpdateCustomerAction();
    const updateEmailAction = useUpdateEmailAction();
    const servicesContext = useContext(
        OtherBoutikServicesContext
    ) as OtherBoutikServices;

    return {
        createCustomer: createCustomer,
        user: { profileHook: useUserProfile, signIn: signIn, signOut: signOut },
        informations: {
            subscribeNewsletter: subscribeNewsletter,
            updateCustomer: updateCustomer,
            updateCustomerEmail: updateEmailAction[0],
        },
        addresses: {
            countryHook: useAllCountries,
            addressesHook: useCustomerAddresses,
            addressByIdHook: useCustomerAddressById,
            addAddress: addAddress,
            removeAddress: removeAddress,
            updateAddress: updateAddress,
        },
        password: {
            changePassword: changePassword,
            completePasswordReset: completePasswordReset,
            requestPasswordReset: requestPasswordReset,
        },
        orders: servicesContext['OrdersService'] as OrdersService,
    };
};
