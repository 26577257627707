module.exports = [{
      plugin: require('../../../node_modules/@sbi-international/boutik.gatsby.plugin.account/dist/gatsby-browser.js'),
      options: {"plugins":[],"currentWebsite":"englander"},
    },{
      plugin: require('../../gatsby-theme-boutik-ecommerce/gatsby-browser.js'),
      options: {"plugins":[],"magentoBackend":"https://magento-production.sbintl.net","siteName":"englander","configPath":"/home/runner/work/boutik.englander/boutik.englander/packages/site-englander/src/i18n/config.json","injectSiteManifestDefinition":"auto","injectManifestPluginDefinition":"auto"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","background_color":"#FFFFFF","theme_color":"#c22322","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"lang":"fr-CA","start_url":"/ca/fr","name":"name","short_name":"short_name","description":"description","localize":[{"lang":"en-CA","start_url":"/ca/en","name":"name","short_name":"short_name","description":"description"},{"lang":"en-US","start_url":"/us/en","name":"name","short_name":"short_name","description":"description"}],"cacheDigest":"e52aa7974194ed3b6c95ab2f6c648a1a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
