// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetGuestEmailOnCartMutationVariables = Types.Exact<{
  cart_id: Types.Scalars['String'];
  email: Types.Scalars['String'];
}>;


export type SetGuestEmailOnCartMutation = { __typename?: 'Mutation', setGuestEmailOnCart?: { __typename?: 'SetGuestEmailOnCartOutput', cart: { __typename?: 'Cart', email?: string | null } } | null };


export const SetGuestEmailOnCartDocument = gql`
    mutation setGuestEmailOnCart($cart_id: String!, $email: String!) {
  setGuestEmailOnCart(input: {cart_id: $cart_id, email: $email}) {
    cart {
      email
    }
  }
}
    `;
export type SetGuestEmailOnCartMutationFn = Apollo.MutationFunction<SetGuestEmailOnCartMutation, SetGuestEmailOnCartMutationVariables>;

/**
 * __useSetGuestEmailOnCartMutation__
 *
 * To run a mutation, you first call `useSetGuestEmailOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGuestEmailOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGuestEmailOnCartMutation, { data, loading, error }] = useSetGuestEmailOnCartMutation({
 *   variables: {
 *      cart_id: // value for 'cart_id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSetGuestEmailOnCartMutation(baseOptions?: Apollo.MutationHookOptions<SetGuestEmailOnCartMutation, SetGuestEmailOnCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGuestEmailOnCartMutation, SetGuestEmailOnCartMutationVariables>(SetGuestEmailOnCartDocument, options);
      }
export type SetGuestEmailOnCartMutationHookResult = ReturnType<typeof useSetGuestEmailOnCartMutation>;
export type SetGuestEmailOnCartMutationResult = Apollo.MutationResult<SetGuestEmailOnCartMutation>;
export type SetGuestEmailOnCartMutationOptions = Apollo.BaseMutationOptions<SetGuestEmailOnCartMutation, SetGuestEmailOnCartMutationVariables>;