// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrdersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrdersQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', orders?: { __typename?: 'CustomerOrders', items: Array<{ __typename?: 'CustomerOrder', id: string, number: string, order_date: string, status: string, total?: { __typename?: 'OrderTotal', grand_total: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } | null } | null> } | null } | null };


export const GetOrdersDocument = gql`
    query getOrders {
  customer {
    orders(pageSize: 100) {
      items {
        id
        number
        order_date
        status
        total {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;