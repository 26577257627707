import { ILoginContext, LoginContext } from './LoginContext';
import React, { useMemo, useState } from 'react';

export interface ILoginProviderProps {
    children: React.ReactNode;
}

export const LoginProvider = (props: { children: ILoginProviderProps }) => {
    const [isQuickLoginVisible, setQuickLoginVisible] = useState(false);
    const { children } = props;

    const contextValue = useMemo<ILoginContext>(() => {
        return {
            isQuickLoginVisible,
            setQuickLoginVisible,
        };
    }, [isQuickLoginVisible, setQuickLoginVisible]);

    return (
        <LoginContext.Provider value={contextValue}>
            {children}
        </LoginContext.Provider>
    );
};
