import {
    PreloadAfterCustomerSignInDocument,
    PreloadAfterCustomerSignInQuery,
    PreloadAfterCustomerSignInQueryVariables,
} from '../graphql/preloadAfterCustomerSignInQuery.generated';

import { IBoutikServiceContextInternalAPI } from '../../boutikService/BoutikServiceContext';
import { invalidateCartCheckoutDetails } from '../../cart/actions/apolloCacheUtils';
import { useApolloClient } from '@apollo/client';
import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useCreateCustomerMutation } from '../graphql';

export interface ICreateCustomerInput {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    isSubscribed: boolean;
}

export const useCreateCustomerAction = () => {
    const { assertNotAuthenticated, boutikService, setAccessToken } =
        useBoutikService() as IBoutikServiceContextInternalAPI;
    const [createCustomerMutation, createCustomerMutationResult] =
        useCreateCustomerMutation();
    const apolloClient = useApolloClient();

    const createCustomer = useCallback(
        async (customer: ICreateCustomerInput) => {
            assertNotAuthenticated();

            const response = await createCustomerMutation({
                variables: customer,
            });

            if (response.data?.generateCustomerToken?.token) {
                // FIXME: Find a better strategy here...
                boutikService.accessToken =
                    response.data.generateCustomerToken.token;

                await apolloClient.query<
                    PreloadAfterCustomerSignInQuery,
                    PreloadAfterCustomerSignInQueryVariables
                >({
                    query: PreloadAfterCustomerSignInDocument,
                    fetchPolicy: 'network-only',
                });

                invalidateCartCheckoutDetails(apolloClient.cache);

                setAccessToken(response.data.generateCustomerToken.token, 3600);
            }
        },
        [
            createCustomerMutation,
            setAccessToken,
            boutikService,
            assertNotAuthenticated,
            apolloClient,
        ]
    );

    return [createCustomer, createCustomerMutationResult] as const;
};
