import { graphql, useStaticQuery } from 'gatsby';

export function useAllProductsPricesSSR() {
    // NOTE: Pay attention to keep this query in sync with the similar, client-side query in
    //       ../graphql/productsPriceAndInventories.graphql.
    //       The following differences are expected:
    //        - This query doesn't gather stock information (items.stock_status and items.only_x_left_in_stock)
    //
    const queryResult = useStaticQuery<{
        [key: string]: { products: { items: any[] } };
    }>(graphql`
        query AllProductPrices {
            magento_englander_fr_ca {
                products(search: "", pageSize: 6500) {
                    items {
                        name
                        sku
                        rating_summary
                        priceRange: price_range {
                            minimumPrice: minimum_price {
                                regularPrice: regular_price {
                                    value
                                    currency
                                }
                                discount {
                                    amountOff: amount_off
                                    percentOff: percent_off
                                }
                                finalPrice: final_price {
                                    value
                                    currency
                                }
                            }
                        }
                    }
                }
            }
            magento_englander_en_ca {
                products(search: "", pageSize: 6500) {
                    items {
                        name
                        sku
                        rating_summary
                        priceRange: price_range {
                            minimumPrice: minimum_price {
                                regularPrice: regular_price {
                                    value
                                    currency
                                }
                                discount {
                                    amountOff: amount_off
                                    percentOff: percent_off
                                }
                                finalPrice: final_price {
                                    value
                                    currency
                                }
                            }
                        }
                    }
                }
            }
            magento_englander_en_us {
                products(search: "", pageSize: 6500) {
                    items {
                        name
                        sku
                        rating_summary
                        priceRange: price_range {
                            minimumPrice: minimum_price {
                                regularPrice: regular_price {
                                    value
                                    currency
                                }
                                discount {
                                    amountOff: amount_off
                                    percentOff: percent_off
                                }
                                finalPrice: final_price {
                                    value
                                    currency
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return queryResult;
}
