import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';
import { useRemoveCouponFromCartMutation } from '../graphql';

export const useRemoveCouponFromCartAction = () => {
    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const [
        setRemoveCouponFromCartMutation,
        setRevomeCouponFromCartMutationResult,
    ] = useRemoveCouponFromCartMutation();

    const useRemoveCouponFromCart = useCallback(async () => {
        const cartId = await getCartId();

        const resp = await setRemoveCouponFromCartMutation({
            variables: {
                cart_id: cartId ?? '',
            },
            update: invalidateCartCheckoutDetails,
        });

        return resp;
    }, [getCartId, setRemoveCouponFromCartMutation]);

    return {
        useRemoveCouponFromCart,
        setRevomeCouponFromCartMutationResult,
    };
};
