export default function popLastIndex(obj: {
    text: string;
    separator?: string;
    padEnd?: boolean;
    endPopNumber?: number;
}) {
    const { text, separator = '/', padEnd, endPopNumber = 1 } = obj;
    const arr = text.split(separator as string);
    if (padEnd) {
        return (
            arr.slice(0, arr.length - endPopNumber).join(separator) + separator
        );
    }
    return arr.slice(0, arr.length - endPopNumber).join(separator);
}
