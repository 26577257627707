import { usePageLocalization } from '@sbi-international/boutik.gatsby.plugin.multilocale';
import { useTranslation } from 'react-i18next';
// Règles d'appellations
// https://progymedia.atlassian.net/jira/software/projects/S5376/boards/122?selectedIssue=S5376-156

export function GetDocDisplayName(document: any, code?: string) {
    const { currentLocale } = usePageLocalization();
    const { t } = useTranslation('layout');

    if (document?.caption) {
        return document?.caption?.[currentLocale];
    }

    const filename = document[currentLocale];

    const regexU = /^\d{5}([U])?_([AF]).+\.pdf$/g;
    const regexI = /^\d{5}([I])?_([AIF]).+\.pdf$/g;
    const regexFA = /^\d{5}([FA])+_.+|.pdf$/g;
    const regexO = /^\d{5}([OU])?_([AFO]).+\.pdf$/g;
    const regexReport = /^.*report.pdf$/g;
    const regexIPEP = /^.*IPEP.*$/g;

    const matchIAF = filename?.match(/^\d{5}_IAF.+.pdf$/g);
    const matchOAF = filename?.match(/^\d{5}_OAF.+.pdf$/g);
    const matchFA = filename?.match(regexFA);
    const matchU = filename?.match(regexU);
    const matchI = filename?.match(regexI);
    const matchO = filename?.match(regexO);

    const filegroups = filename?.split('_');

    if (code && filename?.toLowerCase().includes(code?.toLowerCase())) {
        return t('document.ipep');
    }

    if (
        filename?.includes('46231U_F') ||
        filename?.includes('46231U_A') ||
        filename?.includes('46231_UF') ||
        filename?.includes('46231_UA')
    ) {
        return t('document.guide');
    }

    if (filename?.match(regexReport)) {
        return t('document.report');
    }

    if (filename?.match(regexIPEP)) {
        return t('document.dimensions');
    }

    if (matchO) {
        if (
            (filegroups?.[0]?.includes('O') &&
                (filegroups?.[1].includes('F') ||
                    filegroups?.[1]?.includes('A'))) ||
            (filegroups?.[0]?.includes('U') &&
                (filegroups?.[1].includes('F') ||
                    filegroups?.[1]?.includes('A')))
        )
            return t('document.manual');
    }

    if (matchIAF) {
        return t('document.bilingual-install');
    }

    if (matchOAF) {
        return t('document.bilingual-user');
    }

    if (matchU) {
        if (
            filegroups?.[0]?.includes('U') &&
            (filegroups?.[1]?.includes('F') || filegroups?.[1]?.includes('A'))
        )
            return t('document.wood-stove');
    }

    if (matchI) {
        if (
            filegroups?.[0]?.includes('I') &&
            (filegroups?.[1]?.includes('F') || filegroups?.[1]?.includes('A'))
        )
            return t('document.install-user');
    }

    if (
        filename?.toLowerCase()?.includes('manuel') ||
        filename?.toLowerCase()?.includes('manual')
    ) {
        return t('document.user-manual');
    }

    if (matchFA) {
        return t('document.user-manual');
    }

    return filename;
}
