import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useApplyCouponToCartMutation } from '../graphql';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';

export const useApplyCouponToCartAction = () => {
    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const [setApplyCouponToCartMutation, setApplyCouponToCartMutationResult] =
        useApplyCouponToCartMutation();

    const useApplyCouponToCart = useCallback(
        async (coupon_code: string) => {
            const cartId = await getCartId();

            const resp = await setApplyCouponToCartMutation({
                variables: {
                    coupon_code: coupon_code,
                    cart_id: cartId ?? '',
                },
                update: invalidateCartCheckoutDetails,
            });

            return resp;
        },
        [getCartId, setApplyCouponToCartMutation]
    );

    return {
        useApplyCouponToCart,
        setApplyCouponToCartMutationResult,
    };
};
