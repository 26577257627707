/* eslint-disable react-hooks/rules-of-hooks */
import {
    IProductInventory,
    IProductPrice,
} from '../../../engine/PricesAndInventoriesCache';
import { useEffect, useMemo, useState } from 'react';

import { IStoreContextInternalAPI } from '../StoreContext';
import { useStore } from '../useStore';

export function useProductPrice(
    sku: string | undefined,
    options?: { maxAge?: number }
) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;

    if (!sku) return [undefined] as const;

    const productResource = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return pricesAndInventoriesCache.getProductResource(sku);
    }, [pricesAndInventoriesCache, currentStore, sku]);

    const [price, setPrice] = useState<IProductPrice | undefined>(() =>
        productResource ? productResource.getPriceImmediate() : undefined
    );

    useEffect(() => {
        if (productResource) {
            setPrice(productResource.getPriceImmediate());
            productResource.subscribePrice(setPrice, {
                maxAge: options?.maxAge,
            });

            return () => {
                productResource.unsubscribePrice(setPrice);
            };
        }
    }, [productResource, options, setPrice]);

    return [price] as const;
}

export function useProductsPrices(
    skus: string[],
    options?: { maxAge?: number }
) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productsResources = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return skus.map((sku) => {
            if (!sku) return null;
            return pricesAndInventoriesCache.getProductResource(sku);
        });
    }, [pricesAndInventoriesCache, currentStore, skus]);

    const [prices, setPrices] = useState<(IProductPrice | undefined)[]>(() =>
        productsResources.map((productResource) =>
            productResource ? productResource.getPriceImmediate() : undefined
        )
    );

    return [prices] as const;
}

export function useProductsPricesAndCode(skus: string[]) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productsResources = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return skus.map((sku) => {
            if (!sku) return null;
            return {
                price: pricesAndInventoriesCache.getProductResource(sku),
                sku: sku,
            };
        });
    }, [pricesAndInventoriesCache, currentStore, skus]);

    const [prices, setPrices] = useState<(any | undefined)[]>(() =>
        productsResources.map((productResource) => {
            if (productResource) {
                return {
                    price: productResource.price?.getPriceImmediate(),
                    sku: productResource.sku,
                };
            }
            return undefined;
        })
    );
    return [prices] as const;
}

export function useProductInventory(
    sku: string,
    options?: { maxAge?: number }
) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productResource = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return pricesAndInventoriesCache.getProductResource(sku);
    }, [pricesAndInventoriesCache, currentStore, sku]);

    const [inventory, setInventory] = useState<IProductInventory | undefined>(
        () =>
            productResource
                ? productResource.getInventoryImmediate()
                : undefined
    );

    useEffect(() => {
        if (productResource) {
            setInventory(productResource.getInventoryImmediate());
            productResource.subscribeInventory(setInventory, {
                maxAge: options?.maxAge,
            });
            return () => {
                productResource.unsubscribeInventory(setInventory);
            };
        }
    }, [productResource, options, setInventory]);

    return [inventory] as const;
}
