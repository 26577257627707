import { ImageRepository } from '../interfaces/repositories/imageRepository';
import { ImageService } from '../interfaces/services/imageService';

export class ImageServiceImpl implements ImageService {
    private repository: ImageRepository;

    constructor(repository: ImageRepository) {
        this.repository = repository;
    }

    async getReviewImageIdsBySku(sku: string): Promise<string[]> {
        return await this.repository
            .getReviewImageIdsBySku(sku)
            .then(async (imageNames: string[]) => imageNames)
            .catch((error: any) => {
                throw error;
            });
    }

    async createObjectURLByImageId(imageId: string): Promise<string> {
        return await this.repository
            .createObjectURLByImageId(imageId)
            .then((url: string) => url)
            .catch((error: any) => {
                throw error;
            });
    }
}
