import { createContext } from 'react';

export interface ILoginContext {
    isQuickLoginVisible: boolean;
    setQuickLoginVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoginContext = createContext<ILoginContext>(
    // We don't really want a default value, but createContext requires one,
    // and TypeScript expect it to have correct type.
    undefined as unknown as ILoginContext
);
