import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useChangePasswordMutation } from '../graphql';

export interface IChangePasswordInput {
    currentPassword: string;
    newPassword: string;
}

export const useChangePasswordAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [changePasswordMutation, changePasswordMutationResponse] =
        useChangePasswordMutation();

    const changePassword = useCallback(
        async (passwordInfos: IChangePasswordInput) => {
            assertAuthenticated();

            await changePasswordMutation({
                variables: passwordInfos,
            });
        },
        [changePasswordMutation, assertAuthenticated]
    );

    return [changePassword, changePasswordMutationResponse] as const;
};
