const scrollToRef = (ref: any, gap: number) => {
    if (typeof window !== 'undefined') {
        window.scrollTo({
            left: 0,
            top: ref.current.offsetTop - gap,
            behavior: 'smooth',
        });
    }
};

export default scrollToRef;
