import { createContext } from 'react';

// https://devdocs.magento.com/guides/v2.4/graphql/tutorials/checkout/checkout-shopping-cart.html
// https://devdocs.magento.com/guides/v2.4/graphql/queries/cart.html

export interface ICartContext {
    isQuickCartVisible: boolean;
    isQuickCartLoading: boolean;
    setQuickCartVisible: (visible: boolean) => void;
    setQuickCartLoading: (loading: boolean) => void;
}

export interface ICartContextInternalAPI extends ICartContext {
    // Return the user's active cart id, creating a new cart if required (that is,
    // if the user is not authentified and has not yet made any cart related action).
    getCartId: () => Promise<string | null | undefined>;

    // The active cart id.
    // Might be undefined if the user is not yet authentified and has made no cart action yet.
    activeCartId: string | undefined | null;

    // Invalidate retained information about the current shopping cart. Used after completing
    // checkout to flush existing cart and create a new one. Note that calling this function
    // in other situations is unnecessary and will most likely result in reobtaining the same
    // cart information from the server.
    invalidateCart: () => void;
}

export const CartContext = createContext<ICartContextInternalAPI>(
    // We don't really want a default value, but createContext requires one,
    // and TypeScript expect it to have correct type.
    undefined as unknown as ICartContextInternalAPI
);
