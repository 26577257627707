// FROM: Built by hand, based on:
//    pwa-studio/venia-concept/webpack.config.js: POSSIBLE_TYPES
//    pwa-studio/pwa-buildpack/lib/Utilities/graphQL.js: getPossibleTypes()
//    Which use the following graphQL request on Magento's backend
//        query {
//            __schema {
//                types {
//                    kind
//                    name
//                    possibleTypes {
//                        name
//                    }
//                }
//            }
//        }
// POLICY: Must be kept updated
// -----------------------------------------------------------------------------

const possibleTypes = {
    CartAddressInterface: ['BillingCartAddress', 'ShippingCartAddress'],
    CartItemInterface: [
        'SimpleCartItem',
        'VirtualCartItem',
        'DownloadableCartItem',
        'BundleCartItem',
        'ConfigurableCartItem',
    ],
    ProductInterface: [
        'VirtualProduct',
        'SimpleProduct',
        'DownloadableProduct',
        'BundleProduct',
        'GroupedProduct',
        'ConfigurableProduct',
    ],
    CategoryInterface: ['CategoryTree'],
    MediaGalleryInterface: ['ProductImage', 'ProductVideo'],
    ProductLinksInterface: ['ProductLinks'],
    AggregationOptionInterface: ['AggregationOption'],
    LayerFilterItemInterface: ['LayerFilterItem', 'SwatchLayerFilterItem'],
    PhysicalProductInterface: [
        'SimpleProduct',
        'BundleProduct',
        'GroupedProduct',
        'ConfigurableProduct',
    ],
    CustomizableOptionInterface: [
        'CustomizableAreaOption',
        'CustomizableDateOption',
        'CustomizableDropDownOption',
        'CustomizableMultipleOption',
        'CustomizableFieldOption',
        'CustomizableFileOption',
        'CustomizableRadioOption',
        'CustomizableCheckboxOption',
    ],
    CustomizableProductInterface: [
        'VirtualProduct',
        'SimpleProduct',
        'DownloadableProduct',
        'BundleProduct',
        'ConfigurableProduct',
    ],
    SwatchDataInterface: [
        'ImageSwatchData',
        'TextSwatchData',
        'ColorSwatchData',
    ],
    SwatchLayerFilterItemInterface: ['SwatchLayerFilterItem'],
};

export default possibleTypes;
