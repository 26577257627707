import React, { createContext, useState } from 'react';

import { IAlert } from '../../interfaces/index';

export const feedbackContext = createContext(
    undefined as unknown as {
        addAlert: (alert: IAlert) => void;
        alerts: IAlert[];
        deleteAlert: (index: number) => void;
        removeAllAlert: () => void;
    }
);

export const FeedbackProvider = (props: { children: any }) => {
    const [alerts, setAlerts]: [
        IAlert[],
        React.Dispatch<React.SetStateAction<IAlert[]>>
    ] = useState([] as IAlert[]);
    const { children } = props;
    const addAlert = (alert: IAlert) => {
        setAlerts([...alerts, alert]);
    };

    const deleteAlert = (index: number) => {
        const alertsClone = [...alerts];
        alertsClone.splice(index, 1);

        setAlerts(alertsClone);
    };

    const removeAllAlert = () => {
        setAlerts([] as IAlert[]);
    };

    return (
        <feedbackContext.Provider
            value={{
                alerts,
                addAlert,
                deleteAlert,
                removeAllAlert,
            }}
        >
            {children}
        </feedbackContext.Provider>
    );
};
