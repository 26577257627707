import {
    SetBillingAddressOnCartMutationVariables,
    useSetBillingAddressIdOnCartMutation,
    useSetBillingAddressOnCartMutation,
} from '../graphql';

import { ICartContextInternalAPI } from '../CartContext';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';

export const useSetBillingAddressOnCartAction = () => {
    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const [
        setBillingAddressIdOnCartMutation,
        setBillingAddressIdOnCartMutationResult,
    ] = useSetBillingAddressIdOnCartMutation();

    const setBillingAddressId = useCallback(
        async (customer_address_id: number) => {
            const cartId = await getCartId();

            const resp = await setBillingAddressIdOnCartMutation({
                variables: {
                    customer_address_id,
                    cart_id: cartId,
                },
            });

            return resp;
        },
        [getCartId, setBillingAddressIdOnCartMutation]
    );

    const [
        setBillingAddressOnCartMutation,
        setBillingAddressOnCartMutationResult,
    ] = useSetBillingAddressOnCartMutation();

    const setBillingAddress = useCallback(
        async (address: SetBillingAddressOnCartMutationVariables) => {
            const cartId = await getCartId();

            const resp = await setBillingAddressOnCartMutation({
                variables: {
                    ...address,
                    cart_id: cartId,
                },
            });

            return resp;
        },
        [getCartId, setBillingAddressOnCartMutation]
    );

    return {
        setBillingAddress,
        setBillingAddressOnCartMutationResult,
        setBillingAddressId,
        setBillingAddressIdOnCartMutationResult,
    };
};
