import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useUpdateEmailMutation } from '../graphql';

export interface IUpdateEmailInput {
    email: string;
    password: string;
}

export const useUpdateEmailAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [UpdateEmailMutation, UpdateEmailMutationResponse] =
        useUpdateEmailMutation();

    const updateCustomer = useCallback(
        async (customer: IUpdateEmailInput) => {
            assertAuthenticated();

            await UpdateEmailMutation({
                variables: customer,
            });
        },
        [UpdateEmailMutation, assertAuthenticated]
    );

    return [updateCustomer, UpdateEmailMutationResponse] as const;
};
