import { GetCartContentQuery } from '../context/cart/graphql';
import { MagentoCartQuoteDTOMapper } from '../dto/magentoCartQuoteDTOMapper';
import { CartOperations } from '../interfaces/operations/cartOperations';
import { BoutikEC2ProxyRepository } from '../interfaces/repositories/boutikEC2ProxyRepository';
import { CartService } from '../interfaces/services/cartService';
import { CartQuote, CartQuoteItem } from '../models/cartQuote';

export class CartServiceImpl implements CartService {
    private cartOperations: CartOperations;
    private boutikEC2ProxyRepository: BoutikEC2ProxyRepository;

    constructor(
        cartOperations: CartOperations,
        boutikEC2ProxyRepository: BoutikEC2ProxyRepository
    ) {
        this.cartOperations = cartOperations;
        this.boutikEC2ProxyRepository = boutikEC2ProxyRepository;
    }

    private isSkuMissing(
        currentCart: GetCartContentQuery,
        quoteItem: CartQuoteItem
    ) {
        return (
            currentCart?.cart?.items &&
            !currentCart?.cart?.items.some(
                (item) => item?.product?.sku == quoteItem.sku
            ) &&
            quoteItem.sku
        );
    }

    async createGuestCart(): Promise<void> {
        this.cartOperations.createGuestCartMutation();
    }

    async getCartByQuoteId(quoteId: string): Promise<CartQuote | null> {
        return await this.boutikEC2ProxyRepository
            .getCartQuote(quoteId)
            .then((cart) => {
                return cart && MagentoCartQuoteDTOMapper.toCartQuote(cart);
            })
            .catch(() => null);
    }

    async appendCartQuoteItem(
        cartId: string,
        cartQuote: CartQuote
    ): Promise<void> {
        await this.cartOperations
            .getCartQuery(cartId)
            .then(async (currentCart) => {
                if (cartQuote?.items) {
                    for (const quoteItem of cartQuote.items) {
                        if (this.isSkuMissing(currentCart, quoteItem)) {
                            await this.cartOperations.addProductsToCartMutation(
                                {
                                    cartId: cartId,
                                    sku: quoteItem.sku,
                                    quantity: quoteItem.qty,
                                }
                            );
                        }
                    }
                }
            });
    }
}