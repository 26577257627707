import { IBoutikServiceContextInternalAPI } from '../../boutikService/BoutikServiceContext';
import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useCustomerAccount } from '../';
import { useSignOutMutation } from '../graphql';
import { navigate } from 'gatsby';
import { ICustomerAccountContextActions } from '@sbi-international/boutik.gatsby.plugin.account';

export const useSignOutAction = () => {
    const { isAuthenticated, setAccessToken } =
        useBoutikService() as IBoutikServiceContextInternalAPI;

    const customerAccount =
        useCustomerAccount() as ICustomerAccountContextActions;

    const clearCustomerProfile = () => {
        if (customerAccount) {
            customerAccount.clearCustomerProfile();
        }
    };

    const [signOutMutation, signOutMutationResponse] = useSignOutMutation();
    const signOut = useCallback(async (): Promise<void> => {
        try {
            if (isAuthenticated) {
                await signOutMutation({ fetchPolicy: 'no-cache' });
                navigate('/');
            }
        } catch (error) {
            // Intentionnaly ignore exceptions. This is signout. Nobody really
            // care if the server rejected the signout request. Just destroy
            // local data related to the session, and get over it.
        } finally {
            setAccessToken(undefined);
            clearCustomerProfile();
        }
    }, [
        signOutMutation,
        setAccessToken,
        isAuthenticated,
        clearCustomerProfile,
    ]);
    return [signOut, signOutMutationResponse] as const;
};
