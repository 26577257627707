import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useRequestPasswordResetMutation } from '../graphql';

export const useRequestPasswordResetAction = () => {
    const { assertNotAuthenticated } = useBoutikService();
    const [requestPasswordResetMutation, requestPasswordResetMutationResult] =
        useRequestPasswordResetMutation();

    const requestPasswordReset = useCallback(
        async (email: string) => {
            assertNotAuthenticated();

            await requestPasswordResetMutation({ variables: { email } });
        },
        [requestPasswordResetMutation, assertNotAuthenticated]
    );

    return [requestPasswordReset, requestPasswordResetMutationResult] as const;
};
