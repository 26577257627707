// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import { CustomerAddressFieldsFragmentDoc } from './address.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomerAddressesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerAddressesQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', addresses?: Array<{ __typename?: 'CustomerAddress', id?: number | null, firstname?: string | null, lastname?: string | null, company?: string | null, street?: Array<string | null> | null, city?: string | null, postcode?: string | null, country_code?: Types.CountryCodeEnum | null, telephone?: string | null, default_shipping?: boolean | null, default_billing?: boolean | null, region?: { __typename?: 'CustomerAddressRegion', region?: string | null, region_id?: number | null, region_code?: string | null } | null } | null> | null } | null };


export const GetCustomerAddressesDocument = gql`
    query getCustomerAddresses {
  customer {
    addresses {
      ...CustomerAddressFields
    }
  }
}
    ${CustomerAddressFieldsFragmentDoc}`;

/**
 * __useGetCustomerAddressesQuery__
 *
 * To run a query within a React component, call `useGetCustomerAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerAddressesQuery, GetCustomerAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAddressesQuery, GetCustomerAddressesQueryVariables>(GetCustomerAddressesDocument, options);
      }
export function useGetCustomerAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAddressesQuery, GetCustomerAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAddressesQuery, GetCustomerAddressesQueryVariables>(GetCustomerAddressesDocument, options);
        }
export type GetCustomerAddressesQueryHookResult = ReturnType<typeof useGetCustomerAddressesQuery>;
export type GetCustomerAddressesLazyQueryHookResult = ReturnType<typeof useGetCustomerAddressesLazyQuery>;
export type GetCustomerAddressesQueryResult = Apollo.QueryResult<GetCustomerAddressesQuery, GetCustomerAddressesQueryVariables>;