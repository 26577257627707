// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomerMutationVariables = Types.Exact<{
  firstname: Types.Scalars['String'];
  lastname: Types.Scalars['String'];
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
  isSubscribed: Types.Scalars['Boolean'];
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomerV2?: { __typename?: 'CustomerOutput', customer: { __typename?: 'Customer', firstname?: string | null, lastname?: string | null, email?: string | null, isSubscribed?: boolean | null } } | null, generateCustomerToken?: { __typename?: 'CustomerToken', token?: string | null } | null };


export const CreateCustomerDocument = gql`
    mutation createCustomer($firstname: String!, $lastname: String!, $email: String!, $password: String!, $isSubscribed: Boolean!) {
  createCustomerV2(
    input: {firstname: $firstname, lastname: $lastname, email: $email, password: $password, is_subscribed: $isSubscribed}
  ) {
    customer {
      firstname
      lastname
      email
      isSubscribed: is_subscribed
    }
  }
  generateCustomerToken(email: $email, password: $password) {
    token
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      isSubscribed: // value for 'isSubscribed'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;