import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useUpdateCustomerMutation } from '../graphql';

export interface IUpdateCustomerInput {
    firstname?: string;
    lastname?: string;
    is_subscribed?: boolean;
}

export const useUpdateCustomerAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [updateCustomerMutation, updateAddressMutationResponse] =
        useUpdateCustomerMutation();

    const updateCustomer = useCallback(
        async (customer: IUpdateCustomerInput) => {
            assertAuthenticated();

            await updateCustomerMutation({
                variables: customer,
            });
        },
        [updateCustomerMutation, assertAuthenticated]
    );

    return [updateCustomer, updateAddressMutationResponse] as const;
};
