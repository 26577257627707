import React, { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { CustomerAccountProvider } from '@sbi-international/boutik.gatsby.plugin.account';
import { BoutikServiceContext } from '../boutikService/BoutikServiceContext';
import { useCustomerAccountActions } from '../../hooks/useCustomerAccountActions';

export interface CustomerProviderWrapperProps {
    children: React.ReactNode;
}

export const CustomerProviderWrapper: React.FC<
    CustomerProviderWrapperProps
> = ({ children }) => {
    const boutikService = useContext(BoutikServiceContext);
    const apolloClient = useApolloClient();
    const customerAccountActions = useCustomerAccountActions();

    return (
        <CustomerAccountProvider
            {...{
                boutikService,
                apolloClient,
                customerAccountActions,
            }}
        >
            {children}
        </CustomerAccountProvider>
    );
};
