import {
    useSetShippingAddressesIdOnCartMutation,
    useSetShippingAddressesOnCartMutation,
} from '../graphql';

import { ICartContextInternalAPI } from '../CartContext';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';

export const useSetShippingAddressesOnCartAction = () => {
    const [
        setShippingAddressesOnCartMutation,
        setShippingAddressesOnCartMutationResult,
    ] = useSetShippingAddressesOnCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const addShippingAddress = useCallback(
        async (address: any) => {
            // FIXME address type, codegen input has cart id
            const cartId = await getCartId();

            const resp = await setShippingAddressesOnCartMutation({
                variables: {
                    ...address,
                    cart_id: cartId,
                },
            });

            return resp;
        },
        [getCartId, setShippingAddressesOnCartMutation]
    );

    const [
        setShippingAddressesIdOnCartMutation,
        setShippingAddressesIdOnCartMutationResult,
    ] = useSetShippingAddressesIdOnCartMutation();

    const addShippingAddressId = useCallback(
        async (customer_address_id: number) => {
            const cartId = await getCartId();

            const resp = await setShippingAddressesIdOnCartMutation({
                variables: {
                    customer_address_id,
                    cart_id: cartId,
                },
            });

            return resp;
        },
        [getCartId, setShippingAddressesIdOnCartMutation]
    );

    return {
        addShippingAddress,
        setShippingAddressesOnCartMutationResult,
        addShippingAddressId,
        setShippingAddressesIdOnCartMutationResult,
    };
};
