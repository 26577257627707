import Deferred from './Deferred';
import { debounce } from 'lodash';

type BatchExecutorFn<T> = (items: Set<T>) => Promise<void>;

export class BatchExecutor<T> {
    executorFunction: BatchExecutorFn<T>;
    debouncer: () => void;

    allPendingItems = new Map<T, Promise<void>>();
    pendingBatch = {
        items: new Set<T>(),
        defered: new Deferred<void>(),
    };

    constructor(executorFunction: BatchExecutorFn<T>, delay: number) {
        this.executorFunction = executorFunction;
        this.debouncer = debounce(this.executeBatch.bind(this), delay);
    }

    fetch(item: T): Promise<void> {
        // Check if that item is already pending resolution (possibly from a
        // previous batch that has been launched but has not yet completed)
        const pendingItemPromise = this.allPendingItems.get(item);
        if (pendingItemPromise) return pendingItemPromise;

        // Add the item to the pending batch and take note of it in case we get
        // another request for this same item before it gets resolved
        this.pendingBatch.items.add(item);
        this.allPendingItems.set(item, this.pendingBatch.defered.promise);

        // Trigger or reset the debouncer
        this.debouncer();

        return this.pendingBatch.defered.promise;
    }

    executeBatch() {
        const currentBatch = this.pendingBatch;
        this.pendingBatch = { items: new Set<T>(), defered: new Deferred() };

        this.executorFunction(currentBatch.items)
            .then(() => {
                currentBatch.items.forEach((item) =>
                    this.allPendingItems.delete(item)
                );

                currentBatch.defered.resolve();
            })
            .catch(() => {
                currentBatch.items.forEach((item) =>
                    this.allPendingItems.delete(item)
                );

                currentBatch.defered.reject();
            });
    }
}
