// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPasswordRequirementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPasswordRequirementsQuery = { __typename?: 'Query', storeConfig?: { __typename?: 'StoreConfig', minimumPasswordLength?: string | null, requiredCharacterClassesNumber?: string | null } | null };


export const GetPasswordRequirementsDocument = gql`
    query getPasswordRequirements {
  storeConfig {
    minimumPasswordLength: minimum_password_length
    requiredCharacterClassesNumber: required_character_classes_number
  }
}
    `;

/**
 * __useGetPasswordRequirementsQuery__
 *
 * To run a query within a React component, call `useGetPasswordRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPasswordRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPasswordRequirementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPasswordRequirementsQuery(baseOptions?: Apollo.QueryHookOptions<GetPasswordRequirementsQuery, GetPasswordRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPasswordRequirementsQuery, GetPasswordRequirementsQueryVariables>(GetPasswordRequirementsDocument, options);
      }
export function useGetPasswordRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPasswordRequirementsQuery, GetPasswordRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPasswordRequirementsQuery, GetPasswordRequirementsQueryVariables>(GetPasswordRequirementsDocument, options);
        }
export type GetPasswordRequirementsQueryHookResult = ReturnType<typeof useGetPasswordRequirementsQuery>;
export type GetPasswordRequirementsLazyQueryHookResult = ReturnType<typeof useGetPasswordRequirementsLazyQuery>;
export type GetPasswordRequirementsQueryResult = Apollo.QueryResult<GetPasswordRequirementsQuery, GetPasswordRequirementsQueryVariables>;