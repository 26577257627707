import { CartQuote, CartQuoteItem } from '../models/cartQuote';
import {
    MagentoCartQuote,    
} from '../models/magentoCartQuote';

export class MagentoCartQuoteDTOMapper {
    static toCartQuote(magentoCartQuote: MagentoCartQuote): CartQuote {
        const items: CartQuoteItem[] = [];

        for (const quoteItem of magentoCartQuote.items) {
            items.push({
                sku: quoteItem.sku,
                qty: quoteItem.qty,
                name: quoteItem.name,
            } as CartQuoteItem);
        }

        return { id: magentoCartQuote.id, items: items };
    }
}