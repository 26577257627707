import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useAddProductsToCartMutation } from '../graphql';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';

export const useAddProductsToCartAction = () => {
    const [addProductsToCartMutation, addProductsToCartMutationResult] =
        useAddProductsToCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const addProductToCart = useCallback(
        async (sku: string, quantity: number) => {
            const cartId = await getCartId();

            const resp = await addProductsToCartMutation({
                variables: {
                    cartId,
                    quantity,
                    sku,
                },
                update: invalidateCartCheckoutDetails,
            });

            return resp;
        },
        [addProductsToCartMutation, getCartId]
    );

    return [addProductToCart, addProductsToCartMutationResult] as const;
};
