import { useCallback, useEffect, useRef, useState } from 'react';

// FIXME: Adapter la description
//
// La logique de stockage de l'état du access token est relativement complexe.
// Plutôt que de jumeller ces aspects dans le AuthenticationProvider, on extrait
// toute cette logique ici.
//
// Fonctionnalités importantes:
// 1. Au démarrage de l'application, on vérifie si un access token est déjà
//    conservé dans localStorage. Si oui, et si celui-ci n'est pas expiré, alors
//    on utilise ce token et l'expiration qui lui est associé.
// 2. Après le démarrage, on surveille les changements au localStorage. Ainsi,
//    si l'utilisateur fait un login ou un logout depuis un autre onglet, ce
//    changement sera également reflété dans cette instance ci.
// 3. Le code de l'application peut fournir un nouveau access token ainsi que
//    le délai d'expiration associé à ce token (en seconde). Le code de
//    l'application peut également invalider explicitement le access token
//    (p.ex. suite à un logout).
// 4. Peu importe la façon dont le changement a eu lieu, on doit lancer un timer
//    correspondant au délais d'expiration du token, afin d'invalider celui-ci
//    lorsqu'il sera expiré.
//

type useLocalStorageFunc = <T>(
    keyName: string,
    defaultValue?: T | undefined
) => readonly [T | undefined, (value: T | undefined, ttl?: number) => void];

type LocaleStorageNames = 'localStorage' | 'sessionStorage';

function makeUseLocalStorageBrowser(
    which: LocaleStorageNames
): useLocalStorageFunc | undefined {
    if (typeof window === 'undefined') return undefined;
    const localStorage =
        which === 'localStorage' ? window.localStorage : window.sessionStorage;

    return <T>(keyName: string, defaultValue?: T | undefined) => {
        const [value, setValueInternal] = useState<T>();
        const [expiration, setExpiration] = useState<number>();

        const setValueAndExpirationInternal = useCallback(
            (value: T | undefined, expiration: number | undefined): boolean => {
                if (
                    value !== null &&
                    typeof value !== 'undefined' &&
                    (!expiration || expiration > Date.now())
                ) {
                    setValueInternal(value);
                    setExpiration(expiration);

                    localStorage?.setItem(
                        keyName,
                        JSON.stringify({
                            value: value,
                            expiration,
                        })
                    );

                    return true;
                } else {
                    setValueInternal(undefined);
                    setExpiration(undefined);

                    localStorage?.removeItem(keyName);

                    return false;
                }
            },
            [keyName, setValueInternal, setExpiration]
        );

        // Surveillance des changements provenant d'un autre onglet du navigateur
        useEffect(() => {
            const listener = (event: StorageEvent) => {
                if (event.key === keyName) {
                    const storedValue = event.newValue;
                    if (storedValue) {
                        const { value, expiration } = JSON.parse(storedValue);
                        setValueAndExpirationInternal(value, expiration);
                    } else {
                        setValueAndExpirationInternal(undefined, undefined);
                    }
                }
            };

            window.addEventListener('storage', listener);
            return () => {
                window.removeEventListener('storage', listener);
            };
        }, [keyName, setValueAndExpirationInternal]);

        // Timer pour prendre en charge l'expiration de la valeur.
        useEffect(() => {
            if (expiration) {
                const expirationTimer = setTimeout(() => {
                    setValueAndExpirationInternal(undefined, undefined);
                }, expiration - Date.now());
                return () => {
                    clearTimeout(expirationTimer);
                };
            }
        }, [value, expiration, setValueAndExpirationInternal]);

        const setValue = useCallback(
            (newValue: T | undefined, ttl?: number) => {
                let expiration = undefined;
                if (ttl) {
                    if (ttl < 0) throw new Error("TTL can't be negative");
                    if (newValue === null || newValue === undefined)
                        throw new Error(
                            "TTL can't be set if value is null or undefined"
                        );

                    expiration = Date.now() + ttl * 1000;
                }

                setValueAndExpirationInternal(newValue, expiration);
            },
            [setValueAndExpirationInternal]
        );

        // Chargement de la valeur initiale
        const initialized = useRef<boolean>();
        if (!initialized.current) {
            initialized.current = true;
            const storedValue = localStorage?.getItem(keyName);
            if (storedValue) {
                const { value, expiration } = JSON.parse(storedValue);
                if (setValueAndExpirationInternal(value, expiration))
                    return [value, setValue];
            }
        }

        return [value !== undefined ? value : defaultValue, setValue] as const;
    };
}

// This is a stub implementation of useLocalStorage for use in SSR environment.
function makeUseLocalStorageStub(): useLocalStorageFunc {
    return <T>(keyName: string, defaultValue?: T | undefined) => {
        const [value, setValueInternal] = useState<T>();
        const setValue = useCallback(
            (newValue: T | undefined) => {
                setValueInternal(newValue);
            },
            [setValueInternal]
        );
        return [value !== undefined ? value : defaultValue, setValue] as const;
    };
}

export const useLocalStorage =
    makeUseLocalStorageBrowser('localStorage') || makeUseLocalStorageStub();

export const useSessionStorage =
    makeUseLocalStorageBrowser('sessionStorage') || makeUseLocalStorageStub();
