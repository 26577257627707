import { ICartContextInternalAPI } from '../CartContext';
import { useCartContext } from '../useCartContext';
import { useGetCartCheckoutDetailsQuery } from '../graphql/getCartCheckoutDetailsQuery.generated';

export const useCartCheckoutDetails = () => {
    const { activeCartId } = useCartContext() as ICartContextInternalAPI;
    const hasCart = typeof activeCartId !== 'undefined';

    const { data, loading, error } = useGetCartCheckoutDetailsQuery({
        variables: { cart_id: activeCartId! },
        skip: !hasCart,
    });

    if (!hasCart)
        return [undefined, { loading: false, error: undefined }] as const;

    return [data?.cart ?? undefined, { loading, error }] as const;
};
