import {
    BoutikServiceContext,
    IBoutikServiceContext,
    IBoutikServiceContextInternalAPI,
} from './BoutikServiceContext';
import React, { useCallback, useMemo, useRef } from 'react';

import { ApolloProvider } from '@apollo/client';
import { BoutikService } from '../../engine/BoutikService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useTranslation } from 'react-i18next';

export interface IBoutikServiceProviderProps {
    children: React.ReactNode;
    boutikService: BoutikService;
}

export const BoutikServiceProvider: React.FC<IBoutikServiceProviderProps> = ({
    children,
    boutikService,
}) => {
    const { t } = useTranslation('boutik');
    const [accessToken, setAccessToken0] = useLocalStorage<string>(
        'BoutikService:accessToken'
    );

    // Copy initial accessToken to Apollo
    const initialized = useRef<boolean>();
    if (!initialized.current) {
        initialized.current = true;
        boutikService.accessToken = accessToken;
    }

    // A ref copy of accessToken. This one is used so that assertAuthenticated()
    // and assertNotAuthenticated() utility functions stays stable when
    // accessToken changes.
    const accessTokenRef = useRef<string | undefined>(accessToken);
    accessTokenRef.current = accessToken;

    // Synchronize Magento connection with access token state
    const setAccessToken = useCallback(
        (accessToken: string | undefined, ttl?: number) => {
            boutikService.accessToken = accessToken;
            accessTokenRef.current = accessToken;
            setAccessToken0(accessToken, ttl);
        },
        [boutikService, accessTokenRef]
    );

    const assertAuthenticated = useCallback(() => {
        if (accessTokenRef.current === undefined)
            throw new Error(t('boutik-service.assert-auth'));
    }, [accessTokenRef, t]);

    const assertNotAuthenticated = useCallback(() => {
        if (accessTokenRef.current !== undefined)
            throw new Error(t('boutik-service.assert-not-auth'));
    }, [accessTokenRef, t]);

    const contextValue = useMemo<
        IBoutikServiceContext & IBoutikServiceContextInternalAPI
    >(() => {
        return {
            boutikService,
            isAuthenticated: accessToken !== undefined,
            setAccessToken,
            assertAuthenticated,
            assertNotAuthenticated,
        };
    }, [
        boutikService,
        accessToken,
        setAccessToken,
        assertAuthenticated,
        assertNotAuthenticated,
    ]);

    const memoizedApolloProvider = useMemo(() => {
        return (
            <ApolloProvider client={boutikService.apolloClient}>
                {children}
            </ApolloProvider>
        );
    }, [children, boutikService.apolloClient]);

    return (
        <BoutikServiceContext.Provider value={contextValue}>
            {memoizedApolloProvider}
        </BoutikServiceContext.Provider>
    );
};
