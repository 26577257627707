import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';
import { useRemoveItemFromCartMutation } from '../graphql';

export const useRemoveItemFromCartAction = () => {
    const [removeItemFromCartMutation, removeItemFromCartMutationResult] =
        useRemoveItemFromCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const removeCartItem = useCallback(
        async (cart_item_id: number) => {
            const cartId = await getCartId();

            const resp = await removeItemFromCartMutation({
                variables: {
                    cart_id: cartId,
                    cart_item_id: cart_item_id,
                },
                update: invalidateCartCheckoutDetails,
            });

            return resp;
        },
        [removeItemFromCartMutation, getCartId]
    );

    return [removeCartItem, removeItemFromCartMutationResult] as const;
};
