// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateGuestCartMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateGuestCartMutation = { __typename?: 'Mutation', createEmptyCart?: string | null };


export const CreateGuestCartDocument = gql`
    mutation createGuestCart {
  createEmptyCart
}
    `;
export type CreateGuestCartMutationFn = Apollo.MutationFunction<CreateGuestCartMutation, CreateGuestCartMutationVariables>;

/**
 * __useCreateGuestCartMutation__
 *
 * To run a mutation, you first call `useCreateGuestCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuestCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuestCartMutation, { data, loading, error }] = useCreateGuestCartMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateGuestCartMutation(baseOptions?: Apollo.MutationHookOptions<CreateGuestCartMutation, CreateGuestCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGuestCartMutation, CreateGuestCartMutationVariables>(CreateGuestCartDocument, options);
      }
export type CreateGuestCartMutationHookResult = ReturnType<typeof useCreateGuestCartMutation>;
export type CreateGuestCartMutationResult = Apollo.MutationResult<CreateGuestCartMutation>;
export type CreateGuestCartMutationOptions = Apollo.BaseMutationOptions<CreateGuestCartMutation, CreateGuestCartMutationVariables>;