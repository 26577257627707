// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
export type CustomerAddressFieldsFragment = { __typename?: 'CustomerAddress', id?: number | null, firstname?: string | null, lastname?: string | null, company?: string | null, street?: Array<string | null> | null, city?: string | null, postcode?: string | null, country_code?: Types.CountryCodeEnum | null, telephone?: string | null, default_shipping?: boolean | null, default_billing?: boolean | null, region?: { __typename?: 'CustomerAddressRegion', region?: string | null, region_id?: number | null, region_code?: string | null } | null };

export const CustomerAddressFieldsFragmentDoc = gql`
    fragment CustomerAddressFields on CustomerAddress {
  id
  firstname
  lastname
  company
  street
  city
  postcode
  country_code
  region {
    region
    region_id
    region_code
  }
  telephone
  default_shipping
  default_billing
}
    `;