import React, { useContext } from 'react';
import { FeedbackProvider as CustomerAccountPluginFeedbackProvider } from '@sbi-international/boutik.gatsby.plugin.account';
import { feedbackContext } from './feedbackContext';

export interface FeedbackProviderWrapperProps {
    children: React.ReactNode;
}

export const FeedbackProviderWrapper: React.FC<
    FeedbackProviderWrapperProps
> = ({ children }) => {
    const feedbackContextMethods = useContext(feedbackContext);

    return (
        <CustomerAccountPluginFeedbackProvider
            feedbackContext={feedbackContextMethods}
        >
            {children}
        </CustomerAccountPluginFeedbackProvider>
    );
};
