import {
    BoutikServiceContext,
    IBoutikServiceContext,
} from './BoutikServiceContext';

import { useContext } from 'react';

export const useBoutikService = (): IBoutikServiceContext => {
    const context = useContext(BoutikServiceContext);
    if (typeof context === 'undefined')
        throw Error('BoutikServiceContext is required');
    return context;
};
