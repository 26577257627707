import { CartContext, ICartContext } from './CartContext';

import { useContext } from 'react';

const useCartContext = (): ICartContext => {
    const context = useContext(CartContext);
    
    if (typeof context === 'undefined') throw Error('CartContext is required');
    return context;
};

export { useCartContext };
