// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import { CustomerAddressFieldsFragmentDoc } from './address.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddAddressMutationVariables = Types.Exact<{
  firstname: Types.Scalars['String'];
  lastname: Types.Scalars['String'];
  company: Types.Scalars['String'];
  street?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  city: Types.Scalars['String'];
  regionCode?: Types.InputMaybe<Types.Scalars['String']>;
  regionId?: Types.InputMaybe<Types.Scalars['Int']>;
  regionName?: Types.InputMaybe<Types.Scalars['String']>;
  postcode: Types.Scalars['String'];
  countryCode: Types.CountryCodeEnum;
  telephone: Types.Scalars['String'];
  default_billing?: Types.InputMaybe<Types.Scalars['Boolean']>;
  default_shipping?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type AddAddressMutation = { __typename?: 'Mutation', createCustomerAddress?: { __typename?: 'CustomerAddress', id?: number | null, firstname?: string | null, lastname?: string | null, company?: string | null, street?: Array<string | null> | null, city?: string | null, postcode?: string | null, country_code?: Types.CountryCodeEnum | null, telephone?: string | null, default_shipping?: boolean | null, default_billing?: boolean | null, region?: { __typename?: 'CustomerAddressRegion', region?: string | null, region_id?: number | null, region_code?: string | null } | null } | null };


export const AddAddressDocument = gql`
    mutation addAddress($firstname: String!, $lastname: String!, $company: String!, $street: [String!], $city: String!, $regionCode: String, $regionId: Int, $regionName: String, $postcode: String!, $countryCode: CountryCodeEnum!, $telephone: String!, $default_billing: Boolean, $default_shipping: Boolean) {
  createCustomerAddress(
    input: {firstname: $firstname, lastname: $lastname, company: $company, street: $street, city: $city, region: {region: $regionName, region_code: $regionCode, region_id: $regionId}, postcode: $postcode, country_code: $countryCode, telephone: $telephone, default_billing: $default_billing, default_shipping: $default_shipping}
  ) {
    ...CustomerAddressFields
  }
}
    ${CustomerAddressFieldsFragmentDoc}`;
export type AddAddressMutationFn = Apollo.MutationFunction<AddAddressMutation, AddAddressMutationVariables>;

/**
 * __useAddAddressMutation__
 *
 * To run a mutation, you first call `useAddAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressMutation, { data, loading, error }] = useAddAddressMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      company: // value for 'company'
 *      street: // value for 'street'
 *      city: // value for 'city'
 *      regionCode: // value for 'regionCode'
 *      regionId: // value for 'regionId'
 *      regionName: // value for 'regionName'
 *      postcode: // value for 'postcode'
 *      countryCode: // value for 'countryCode'
 *      telephone: // value for 'telephone'
 *      default_billing: // value for 'default_billing'
 *      default_shipping: // value for 'default_shipping'
 *   },
 * });
 */
export function useAddAddressMutation(baseOptions?: Apollo.MutationHookOptions<AddAddressMutation, AddAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAddressMutation, AddAddressMutationVariables>(AddAddressDocument, options);
      }
export type AddAddressMutationHookResult = ReturnType<typeof useAddAddressMutation>;
export type AddAddressMutationResult = Apollo.MutationResult<AddAddressMutation>;
export type AddAddressMutationOptions = Apollo.BaseMutationOptions<AddAddressMutation, AddAddressMutationVariables>;