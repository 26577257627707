import React from 'react';
import rightArrowIcon from '@progymedia/sbi-web-englander/src/images/englander-icons/Right-Arrow-Icon.svg';

export const cookieConsentConfig = {
    containerId: process.env.GATSBY_GTM_CONTAINER,    
    descriptionSmall: {
        en_CA: 'With your consent, we use cookies to improve your browsing experience, deliver personalized ads or content, and analyze visits to this website. By clicking on “Accept all”, you agree [to our privacy and cookies policy.](/ca/en/privacy-policy/)',
        en_US: 'With your consent, we use cookies to improve your browsing experience, deliver personalized ads or content, and analyze visits to this website. By clicking on “Accept all”, you agree [to our privacy and cookies policy.](/us/en/privacy-policy/)',
        fr_CA: 'Avec votre consentement, nous utilisons des cookies pour améliorer votre expérience de navigation, diffuser des publicités ou des contenus personnalisés et analyser les visites à ce site Web. En cliquant sur « Tout accepter », vous consentez [à notre politique de confidentialité et cookies.](/ca/fr/politiques-de-confidentialite-et-cookies/)',
    },
    innerButtonAcceptAll: {
        en_CA: <div><span>{"Accept all"}</span><img className="cookie-consent-arrow" src={rightArrowIcon} alt="react logo" /></div>,
        en_US: <div><span>{"Accept all"}</span><img className="cookie-consent-arrow" src={rightArrowIcon} alt="react logo" /></div>,
        fr_CA: <div><span>{"Tout accepter"}</span><img className="cookie-consent-arrow" src={rightArrowIcon} alt="react logo" /></div>,
    },
};
