import React, { useContext } from 'react';
import { useApolloClient } from '@apollo/client';

import {
    OtherBoutikServices,
    OtherBoutikServicesContext,
    injectHookServices,
} from './otherBoutikServicesContext';
import { invalidateCartCheckoutDetails } from '../cart/actions/apolloCacheUtils';

export interface IOtherBoutikServicesProviderProps {
    children: React.ReactNode;
}

export const OtherBoutikServicesProvider: React.FC<
    IOtherBoutikServicesProviderProps
> = (props: IOtherBoutikServicesProviderProps) => {
    const apolloClient = useApolloClient();
    
    const otherBoutikServicesContext = useContext(
        OtherBoutikServicesContext
    ) as OtherBoutikServices;

    injectHookServices(otherBoutikServicesContext, apolloClient, invalidateCartCheckoutDetails); 

    return (
        <OtherBoutikServicesContext.Provider value={otherBoutikServicesContext}>
            {props.children}
        </OtherBoutikServicesContext.Provider>
    );
};
