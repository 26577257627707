import {
    GlobalLocalizationProvider,
    PageLocalizationProvider,
} from '@sbi-international/boutik.gatsby.plugin.multilocale';
import { BoutikService } from './src/engine/BoutikService';
import { BoutikServiceProvider } from './src/context/boutikService';
import { OtherBoutikServicesProvider } from './src/context/otherBoutikServices/OtherBoutikServicesProvider';
import { CartProvider } from './src/context/cart';
import { FeedbackProvider } from './src/context/feedback/feedbackContext';
import { CustomerProviderWrapper } from './src/context/customerAccount/CustomerProviderWrapper';
import { FeedbackProviderWrapper } from './src/context/feedback/FeedbackProviderWrapper';
import { LoginProvider } from './src/context/login';
import { LocationProvider } from '@reach/router';
import React from 'react';
import { StoreProvider } from './src/context/store';
import { cookieConsentConfig } from './cookieConsentConfig';
import { CookiesConsentProvider } from '@sbi-international/boutik.package.cookie.consent';
import { ServicesContextProvider } from '@sbi-international/boutik.gatsby.plugin.services/dist/src/contexts/ServicesContextProvider';

export const onServiceWorkerUpdateReady = () => {
    /* TODO update only when new version

    if (answer === true) {*/
    window.location.reload();
    /*} */
};

export const wrapRootElement = ({ element }, { magentoBackend }) => {
    const apiBase =
        typeof window !== 'undefined' ? window.location?.origin : null;

    const boutikService = new BoutikService({
        apiBase: apiBase || magentoBackend,
    });

    return (
        <GlobalLocalizationProvider>
            <FeedbackProvider>
                <ServicesContextProvider>
                    <BoutikServiceProvider boutikService={boutikService}>
                        <OtherBoutikServicesProvider>
                            <CustomerProviderWrapper>
                                <FeedbackProviderWrapper>
                                    <LocationProvider>
                                        <CookiesConsentProvider
                                            config={cookieConsentConfig}
                                        >
                                            {element}
                                        </CookiesConsentProvider>
                                    </LocationProvider>
                                </FeedbackProviderWrapper>
                            </CustomerProviderWrapper>
                        </OtherBoutikServicesProvider>
                    </BoutikServiceProvider>
                </ServicesContextProvider>
            </FeedbackProvider>
        </GlobalLocalizationProvider>
    );
};

export const wrapPageElement = ({ element, props: { pageContext } }) => {
    return (
        <PageLocalizationProvider pageContext={pageContext}>
            <StoreProvider>
                <CartProvider>
                    <LoginProvider>{element}</LoginProvider>
                </CartProvider>
            </StoreProvider>
        </PageLocalizationProvider>
    );
};
