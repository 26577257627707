import { ILoginContext, LoginContext } from './LoginContext';

import { useContext } from 'react';

const useLoginContext = (): ILoginContext => {
    const context = useContext(LoginContext);

    if (typeof context === 'undefined') throw Error('LoginContext is required');
    return context;
};

export { useLoginContext };
