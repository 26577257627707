import { ICartContextInternalAPI } from '../CartContext';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';
import { useSetShippingMethodsOnCartMutation } from '../graphql';

export const useSetShippingMethodsOnCartAction = () => {
    const [
        setShippingMethodsOnCartMutation,
        setShippingMethodsOnCartMutationResult,
    ] = useSetShippingMethodsOnCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const setShippingMethod = useCallback(
        async (carrier_code: string, method_code: string) => {
            const cartId = await getCartId();

            const resp = await setShippingMethodsOnCartMutation({
                variables: {
                    cart_id: cartId,
                    carrier_code: carrier_code,
                    method_code: method_code,
                },
            });

            return resp;
        },
        [getCartId, setShippingMethodsOnCartMutation]
    );

    return [setShippingMethod, setShippingMethodsOnCartMutationResult] as const;
};
