import { BoutikService } from '../../engine/BoutikService';
import { createContext } from 'react';

export interface IBoutikServiceContext {
    boutikService: BoutikService;
    isAuthenticated: boolean;
    assertAuthenticated: () => void;
    assertNotAuthenticated: () => void;
}

export interface IBoutikServiceContextInternalAPI
    extends IBoutikServiceContext {
    setAccessToken: (accessToken: string | undefined, ttl?: number) => void;
}

export const BoutikServiceContext = createContext<IBoutikServiceContext>(
    undefined as unknown as IBoutikServiceContext
);
