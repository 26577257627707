// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerMutationVariables = Types.Exact<{
  firstname?: Types.InputMaybe<Types.Scalars['String']>;
  lastname?: Types.InputMaybe<Types.Scalars['String']>;
  is_subscribed?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomerV2?: { __typename?: 'CustomerOutput', customer: { __typename?: 'Customer', firstname?: string | null, lastname?: string | null, isSubscribed?: boolean | null } } | null };


export const UpdateCustomerDocument = gql`
    mutation updateCustomer($firstname: String, $lastname: String, $is_subscribed: Boolean) {
  updateCustomerV2(
    input: {firstname: $firstname, lastname: $lastname, is_subscribed: $is_subscribed}
  ) {
    customer {
      firstname
      lastname
      isSubscribed: is_subscribed
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      is_subscribed: // value for 'is_subscribed'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;