import { graphql, useStaticQuery } from 'gatsby';

import { useMemo } from 'react';
import { useStore } from '../../store';

export function useAllCountries() {
    const { currentStore } = useStore();

    // JWH: Review once we have implemented a better strategy
    const queryResult = useStaticQuery<{
        [key: string]: {
            countries: [
                {
                    id: string;
                    full_name_locale: string;
                    full_name_english: string;
                    available_regions: [
                        { code: string; id: string; name: string }
                    ];
                }
            ];
        };
    }>(graphql`
        query AllCountries {
            magento_englander_fr_ca {
                countries {
                    id
                    full_name_locale
                    full_name_english
                    available_regions {
                        code
                        id
                        name
                    }
                }
            }
            magento_englander_en_ca {
                countries {
                    id
                    full_name_locale
                    full_name_english
                    available_regions {
                        code
                        id
                        name
                    }
                }
            }
            magento_englander_en_us {
                countries {
                    id
                    full_name_locale
                    full_name_english
                    available_regions {
                        code
                        id
                        name
                    }
                }
            }
        }
    `);

    return useMemo(() => {
        const countries = queryResult?.[`magento_${currentStore}`]?.countries;

        const getCountryNameByCode = (
            countryCode: string,
            currentLocale: string
        ) => {
            if (currentLocale === 'fr_CA') {
                return countries.find((country) => country.id === countryCode)
                    ?.full_name_locale;
            } else {
                return countries.find((country) => country.id === countryCode)
                    ?.full_name_english;
            }
        };

        const getCountryCodeByName = (
            countryName: string,
            currentLocale: string
        ) => {
            if (currentLocale === 'fr_CA') {
                return countries.find(
                    (country) => country.full_name_locale === countryName
                )?.id;
            } else {
                return countries.find(
                    (country) => country.full_name_english === countryName
                )?.id;
            }
        };

        const getRegionsByCountryName = (
            countryName: string,
            currentLocale: string
        ) => {
            if (currentLocale === 'fr_CA') {
                return countries.find(
                    (country) => country.full_name_locale === countryName
                )?.available_regions;
            } else {
                return countries.find(
                    (country) => country.full_name_english === countryName
                )?.available_regions;
            }
        };

        const getRegionObject = (
            countryName: string,
            regionName: string,
            currentLocale: string
        ) => {
            if (currentLocale === 'fr_CA') {
                const regions = countries.find(
                    (country) => country.full_name_locale === countryName
                )?.available_regions;
                return regions?.find((region) => region.name === regionName);
            } else {
                const regions = countries.find(
                    (country) => country.full_name_english === countryName
                )?.available_regions;
                return regions?.find((region) => region.name === regionName);
            }
        };

        return {
            countries,
            getCountryNameByCode,
            getCountryCodeByName,
            getRegionsByCountryName,
            getRegionObject,
        };
    }, [queryResult, currentStore]);
}
