// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompletePasswordResetMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  resetPasswordToken: Types.Scalars['String'];
  newPassword: Types.Scalars['String'];
}>;


export type CompletePasswordResetMutation = { __typename?: 'Mutation', resetPassword?: boolean | null, generateCustomerToken?: { __typename?: 'CustomerToken', token?: string | null } | null };


export const CompletePasswordResetDocument = gql`
    mutation completePasswordReset($email: String!, $resetPasswordToken: String!, $newPassword: String!) {
  resetPassword(
    email: $email
    resetPasswordToken: $resetPasswordToken
    newPassword: $newPassword
  )
  generateCustomerToken(email: $email, password: $newPassword) {
    token
  }
}
    `;
export type CompletePasswordResetMutationFn = Apollo.MutationFunction<CompletePasswordResetMutation, CompletePasswordResetMutationVariables>;

/**
 * __useCompletePasswordResetMutation__
 *
 * To run a mutation, you first call `useCompletePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePasswordResetMutation, { data, loading, error }] = useCompletePasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      resetPasswordToken: // value for 'resetPasswordToken'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useCompletePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<CompletePasswordResetMutation, CompletePasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompletePasswordResetMutation, CompletePasswordResetMutationVariables>(CompletePasswordResetDocument, options);
      }
export type CompletePasswordResetMutationHookResult = ReturnType<typeof useCompletePasswordResetMutation>;
export type CompletePasswordResetMutationResult = Apollo.MutationResult<CompletePasswordResetMutation>;
export type CompletePasswordResetMutationOptions = Apollo.BaseMutationOptions<CompletePasswordResetMutation, CompletePasswordResetMutationVariables>;