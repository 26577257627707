import { ICartContextInternalAPI } from '../CartContext';
import { useCartContext } from '../useCartContext';
import { useGetCartContentQuery } from '../graphql';

export const useCartContent = () => {
    const { activeCartId } = useCartContext() as ICartContextInternalAPI;
    const hasCart = typeof activeCartId !== 'undefined';

    const { data, loading, error } = useGetCartContentQuery({
        variables: { cart_id: activeCartId! },
        skip: !hasCart,
    });

    if (!hasCart)
        return [undefined, { loading: false, error: undefined }] as const;

    return [data?.cart ?? undefined, { loading, error }] as const;
};
