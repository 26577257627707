import {
    CustomerAccountContext,
    ICustomerAccountContext,
} from '@sbi-international/boutik.gatsby.plugin.account';

import { useContext } from 'react';

const useCustomerAccount = (): ICustomerAccountContext => {
    const context = useContext(CustomerAccountContext);
    return context;
};

export { useCustomerAccount };
