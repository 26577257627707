/* eslint-disable react-hooks/exhaustive-deps */
import { CartContext, ICartContextInternalAPI } from './CartContext';
import {
    CreateGuestCartDocument,
    CreateGuestCartMutation,
    CreateGuestCartMutationVariables,
    GetCustomerCartDocument,
    GetCustomerCartQuery,
    GetCustomerCartQueryVariables,
    MergeCartsDocument,
} from './graphql';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    invalidateCartCheckoutDetails,
    invalidateCartFull,
} from './actions/apolloCacheUtils';

import { useApolloClient } from '@apollo/client';
import { useCustomerAccount } from '../customerAccount';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useStore } from '../store';

export interface ICartProviderProps {
    children: React.ReactNode;
}

interface ICartMeta {
    type: 'guest' | 'customer';
    id: string | null | undefined;
    store: string;
    owner?: string;
}

export const CartProvider: React.FC<ICartProviderProps> = ({ children }) => {
    const { isIdentified, currentUser } = useCustomerAccount();
    const { currentStore } = useStore();

    const [cartMeta, setCartMeta] = useLocalStorage<ICartMeta | undefined>(
        'Cart:cartMeta'
    );
    const [isQuickCartVisible, setQuickCartVisible] = useState(false);
    const [isQuickCartLoading, setQuickCartLoading] = useState(false);

    const apolloClient = useApolloClient();

    useEffect(() => {
        (async () => {
          
            // IIAFE because useEffect doesn't allow async functions
            if (!isIdentified) {
                // FIXME: This is a temp fix for a bug in Magento 2.4.1; review once 2.4.2 is out and deployed (expected on Feb 9th 2021)
                if (
                    cartMeta &&
                    (cartMeta.type !== 'guest' ||
                        cartMeta.store !== currentStore)
                ) {
                    if (cartMeta.store !== currentStore)
                        console.warn(
                            `Destroying current cart because it is attached to wrong store`
                        );

                    setCartMeta(undefined);
                    invalidateCartFull(apolloClient.cache);
                }
            } else {
                
                // FIXME: This is a temp fix for a bug in Magento 2.4.1; review once 2.4.2 is out and deployed (expected on Feb 9th 2021)
                if (
                    !cartMeta ||
                    cartMeta.type === 'guest' ||
                    cartMeta.store !== currentStore ||
                    cartMeta.owner !== currentUser?.email
                ) {
                    // User does not yet have a cart (or we don't know about it yet), or has a guest cart
                    // Get the customer's cart from the backend, then merge it with the guest cart if appropriate.
                    apolloClient
                        .query<
                            GetCustomerCartQuery,
                            GetCustomerCartQueryVariables
                        >({
                            query: GetCustomerCartDocument,
                            fetchPolicy: 'network-only',
                        })
                        .then((result) => {
                            const newCustomerCartData = result.data;

                            if (cartMeta?.type) {
                                if (cartMeta?.type === 'guest') {                                    
                                    apolloClient
                                        .mutate({
                                            mutation: MergeCartsDocument,
                                            variables: {
                                                source_cart_id: cartMeta.id,
                                                destination_cart_id:
                                                    newCustomerCartData
                                                        .customerCart.id,
                                            },
                                            update: invalidateCartCheckoutDetails,
                                        })
                                        .catch((e) => {
                                            console.error(
                                                `Failed to merge guest cart: `,
                                                e
                                            );
                                        });
                                } else {
                                    console.error(
                                        `Existing cart can't be merged with new customer cart`
                                    );
                                }
                            }

                            setCartMeta({
                                type: 'customer',
                                id: newCustomerCartData.customerCart.id,
                                store: currentStore!,
                                owner: currentUser?.email,
                            });
                        });
                }
            }
        })().catch(console.error);
    }, [
        apolloClient,
        cartMeta,
        currentStore,
        currentUser?.email,
        isIdentified,
    ]);

    const invalidateCart = useCallback(() => {
        setCartMeta(undefined);
        invalidateCartFull(apolloClient.cache);
    }, [apolloClient, apolloClient.cache]);

    const getCartId = useCallback(async () => {
        if (cartMeta?.id) return cartMeta.id;

        if (isIdentified)
            throw new Error('User is identified but has no cart yet');
        if (!currentStore) throw new Error('currentStore is not known yet');

        try {
            const { data: cartData } = await apolloClient.mutate<
                CreateGuestCartMutation,
                CreateGuestCartMutationVariables
            >({ mutation: CreateGuestCartDocument, fetchPolicy: 'no-cache' });

            setCartMeta({
                type: 'guest',
                id: cartData?.createEmptyCart,
                store: currentStore,
            });

            return cartData?.createEmptyCart;
        } catch (e) {
            console.error(`Failed to acquire customer cart`, e);
            throw e;
        }
    }, [cartMeta?.id, isIdentified, currentStore]);

    const contextValue = useMemo<ICartContextInternalAPI>(
        () => ({
            activeCartId: cartMeta?.id,
            getCartId,
            isQuickCartVisible,
            setQuickCartVisible,
            isQuickCartLoading,
            setQuickCartLoading,
            invalidateCart,
        }),
        [cartMeta, cartMeta?.id, isQuickCartVisible, isQuickCartLoading]
    );

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};
