import { useGetUserProfileQuery } from '../graphql';

import { useMemo } from 'react';

export const useUserProfile = (isAuthenticated: boolean) => {
    const profileQuery = useGetUserProfileQuery({
        fetchPolicy: 'cache-first',
        skip: !isAuthenticated,
    });

    const profile = useMemo(() => {
        return profileQuery.data?.customer || {};
    }, [profileQuery]);
    return [profile, profileQuery] as const;
};
