import {
    GetCustomerAddressesDocument,
    GetCustomerAddressesQuery,
    GetCustomerAddressesQueryVariables,
    useAddAddressMutation,
} from '../graphql';

import { Types } from '../../../engine/apis/magento2';
import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';

export interface IAddAddressInput {
    firstname: string;
    lastname: string;
    company: string;

    street: string[];
    city: string;
    regionCode?: string;
    regionId?: number;
    regionName?: string;
    postcode: string;
    countryCode: Types.CountryCodeEnum;

    default_shipping: boolean;
    default_billing: boolean;

    telephone: string;
}

export const useAddAddressAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [addAddressMutation, addAddressMutationResponse] =
        useAddAddressMutation();

    const addAddress = useCallback(
        async (address: IAddAddressInput) => {
            assertAuthenticated();

            if (address?.regionCode === null || address?.regionCode === '') {
                delete address.regionCode;
                delete address.regionId;
            }

            await addAddressMutation({
                variables: address,
                update: (cache, addAddressMutationResponse) => {
                    const customerAddresses = cache.readQuery<
                        GetCustomerAddressesQuery,
                        GetCustomerAddressesQueryVariables
                    >({ query: GetCustomerAddressesDocument });

                    if (
                        customerAddresses?.customer?.addresses &&
                        addAddressMutationResponse.data?.createCustomerAddress
                    ) {
                        const updatedCustomerAddresses: GetCustomerAddressesQuery =
                            {
                                ...customerAddresses,
                                customer: {
                                    ...customerAddresses.customer,
                                    addresses: [
                                        ...customerAddresses.customer.addresses,
                                        addAddressMutationResponse.data
                                            .createCustomerAddress,
                                    ],
                                },
                            };

                        cache.writeQuery<
                            GetCustomerAddressesQuery,
                            GetCustomerAddressesQueryVariables
                        >({
                            query: GetCustomerAddressesDocument,
                            data: updatedCustomerAddresses,
                        });
                    }
                },
            });
        },
        [addAddressMutation, assertAuthenticated]
    );

    return [addAddress, addAddressMutationResponse] as const;
};
